<template>
  <Section class="hidden md:block">

    <Container>

      <FadeIn class="mx-auto max-w-2xl lg:text-center">
        <div><strong class="text-pretitle" v-if="content.pretitle">{{ content.pretitle }}</strong></div>
        <h2 class="text-section-title">{{ content.title }}</h2>
        <p class="text-subtitle" v-interpolation v-html="content.subtitle"></p>
      </FadeIn>

      <FadeIn class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">

        <div v-for="cat in cats">
          <div v-if="block[cat.api].length">
            <h3 class="text-md md:text-lg font-semibold leading-6 md:leading-6 text-gray-900 mb-2 md:border-b border-[#E4E2DF] md:border-toggl pb-2">
              <NuxtLink
              v-if="cat.slug"
              :to="`/blog/${cat.slug}`"
              >
                {{ cat.title }}
              </NuxtLink>
              <NuxtLink
              v-else
              to="/blog"
              >
                {{ cat.title }}
              </NuxtLink>
            </h3>
      
            <article class="mt-8 sm:mt-12 md:mt-0 md:flex md:items-start md:pb-2 mb-2 md:border-b border-[#E4E2DF] md:border-toggl group" v-for="(article, index) in block[cat.api]">
              <NuxtLink 
              :to="`/articles/${article.slug}`"
              class="block md:mr-2 md:max-w-[65px] md:flex-shrink-0"
              :aria-label="article.title"
              >
                <ImageResponsive
                v-if="article?.image?.url"
                :classes="`rounded !mb-0 !md:w-[65px] aspect-[${article?.image?.width}/${article?.image?.height}]`"
                :width="article?.image?.width"
                :height="article?.image?.height"
                :src="article?.image?.url"
                :alt="article?.image?.alternativeText"
                :xs="12"
                :sm="12"
                :md="4"
                :lg="2"
                :xl="2"
                :lazy="true"
                />
              </NuxtLink>		  
              <div class="max-w-xl mt-2 md:mt-0">
                <div class="group relative">
                  <NuxtLink :to="`/articles/${article.slug}`">
                    <h3 class="leading-4 md:leading-4 text-gray-900" style="font-size: 14px">
                      {{ article.title }}
                    </h3>
                  </NuxtLink>
                </div>
              </div>
            </article>
          </div>
        </div>

      </FadeIn>

      <FadeIn class="mx-auto mt-16 flex justify-center">
        <ElementButton 
        :url="type === 'articles' ? '/blog' : '/webinars'" 
        type="primary"
        >
          View all {{ type === 'articles' ? 'blog posts' : 'webinars' }}
        </ElementButton>
      </FadeIn>

    </Container>

  </Section>
</template>

<script setup>
const route = useRoute()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  exclude: {
    type: String,
    default: null
  },
  type: {
    type: String,
    default: 'articles'
  }
})

const block = props.content

const cats = [
  {
    title: 'Respect At Work',
    api: 'catRespectAtWork',
    slug: 'respect-at-work'
  },
  {
    title: 'Psychosocial Health',
    api: 'catPsychosocial',
    slug: false
  },
  {
    title: 'Whistleblowing',
    api: 'catWhistleblowing',
    slug: false
  },
  {
    title: 'Cultural Change',
    api: 'catCulturalChange',
    slug: 'cultural-change'
  },
  {
    title: 'Reporting',
    api: 'catReporting',
    slug: 'reporting'
  },
  {
    title: 'Compliance',
    api: 'catCompliance',
    slug: 'compliance'
  }
]
</script>